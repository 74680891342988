import React from 'react'
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from '../components/AppLayout'
import PageInfo from '../components/PageInfo'
import Brand from '../components/shared/Brand'
import Typography from '@material-ui/core/Typography'
import {
	ArticleBody,
	ArticleCopy,
	ArticleHeaderImg,
	ArticleImg,
	ArticleList,
	ArticleListItem,
	ArticlePage
} from '../components/common/Article'
import AnchorLink from '../components/shared/AnchorLink'
import RouterLink from '../components/shared/RouterLink'
import { graphql } from 'gatsby'
import UnsplashPhotoCredit from '../components/common/UnsplashPhotoCredit'
import { SchemaMarkupArticleAsSupplementalBlog } from '../components/common/SchemaMarkup'

export default function PoorTimeManagement(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();

	const title = "The Effects of Poor Time Management. Do You Recognize The Warning Signs?";

	return (
		<AppLayout overlayNavWhenWide>
			<PageInfo
				title={"Poor Time Management & The Effects You Should Know - " + productName}
				description={"We know time is a precious resource. Are you aware of signs that you're wasting it?"}
			/>
			<SchemaMarkupArticleAsSupplementalBlog/>

			<ArticlePage>
				<ArticleHeaderImg
					alt="Effects of Poor Time Management"
					caption={<UnsplashPhotoCredit author="Jan Huber" href="https://unsplash.com/@jan_huber"/>}
					imgData={data.headerImg.childImageSharp.gatsbyImageData}
					articleTitle={title}
				/>

				<ArticleBody articleTitle={title}>
					<ArticleCopy>
						<strong>Are your warning bells ringing when you read that title?</strong>
						{' '}
						We usually realize the value of time. Yet our subconscious mind
						doesn't always treat time as such a precious resource.
					</ArticleCopy>

					<ArticleCopy>
						This is especially true when we compare it to other resources such as money.
					</ArticleCopy>

					<ArticleCopy>
						So while we are focusing on time, it's important to call out some
						signs of poor time management so that you can avoid them.
					</ArticleCopy>

					<ArticleCopy>
						Let's dive in...
					</ArticleCopy>

					<Typography variant="h2" gutterBottom>
						Are You Getting The Most Out of Your Time?
					</Typography>

					<ArticleCopy>
						With all the technology we have available these days, we've become more
						productive in general. But one reason our productivity can remain hindered
						is that we're not using the tools available to us. Are you evaluating
						updated processes and tools that will save you time and make your job
						easier? Are your existing tools outdated? You may need to check the
						processes or tools in your workflow. Are there any that aren't as proficient
						as they could be when compared to other modern alternatives? No one will
						call you an old dog if you're learning new tricks.
					</ArticleCopy>

					<ArticleCopy>
						As you're evaluating your tools, how does your time management stack up?
						It's important to know how you and your team members are spending their time
						as well. Then you can focus more on what actions are producing results.
						We've been using our <RouterLink to="/timesheet-app/">timesheet app</RouterLink>
						{' '}
						to not only track time spent on clients, but also to keep a record
						of how long projects take.
					</ArticleCopy>

					<ArticleCopy>
						<strong>Are you unsure if you need to rethink your time management?</strong>
						{' '}
						Let's take a look at some common warning signs of poor time
						management and their effects:
					</ArticleCopy>

					<ArticleList gutters>
						<ArticleListItem title="Procrastination">
							Are you pushing off work to the last minute? If you have trouble getting
							started, it's sometimes that little push that may get you going. For
							going to the gym, you might need to just walk out the door. Now that
							you've started, you're going to finish the job. The same can be true
							for <RouterLink to="/freelance-time-tracking/">freelancers</RouterLink> and
							hourly workers that need to just clock in to get that initial push.
						</ArticleListItem>

						<ArticleListItem title="Lack of sleep">
							Are your working hours conducive to your
							{' '}<RouterLink to="/blog/attempting-to-transform-a-night-owl-into-a-morning-person/">sleep cycle</RouterLink>?
							Not everyone is a morning person, and you may be more productive with
							alternative hours. Regardless, try to maintain a consistent sleep
							schedule.
						</ArticleListItem>

						<ArticleImg
							alt="Sleepy"
							caption={<UnsplashPhotoCredit author="Jem Sahagun" href="https://unsplash.com/@jemsahagun"/>}
							imgData={data.sleepyCatImg.childImageSharp.gatsbyImageData}
							indented
						/>

						<ArticleListItem title="Unhealthy eating or poor exercise">
							A common excuse for not exercising is not having enough time.
							But in reality it's about having poor time management.
							If you're not taking care of your body, your mind may be suffering, and
							you're likely not getting the most value out of your time.
						</ArticleListItem>

						<ArticleListItem title="Low energy">
							If you're not feeling as dedicated or enthusiastic, there can be various
							reasons for this. You may consider an evaluation, whether by self
							reflection or with the help of a professional.
						</ArticleListItem>

						<ArticleListItem title="Lack of punctuality">
							If you're showing up late and leaving early, you may be leaving
							productivity on the table. Additionally, it's important to find a
							schedule for collaboration if you work with others.
						</ArticleListItem>

						<ArticleListItem title="Rushed work">
							Are you regularly finishing up your work in a hurry? You may be
							overloaded, overcommitted, or subject to unrealistic deadlines.
							Try to manage your time by not saying yes to everything.
						</ArticleListItem>

						<ArticleImg
							alt="Car Time Lapse"
							caption={<UnsplashPhotoCredit author="Radek Kilijanek" href="https://unsplash.com/@radek_blackseven"/>}
							imgData={data.highwaySunsetTimeLapseImg.childImageSharp.gatsbyImageData}
							indented
						/>

						<ArticleListItem title="Regular distractions">
							Is your environment distracting? Sounds, lights, or other concerns can
							cause wasted time. You may want to consider what helps you stay on
							track.
						</ArticleListItem>

						<ArticleListItem title="Disorganization">
							If you find yourself disorganized, you may be wasting time while
							searching for items. Use your time management skills and tools to
							organize and focus on your day-to-day life.
						</ArticleListItem>

						<ArticleListItem title="Poor work/life balance">
							Are you a bit of a workaholic? Or are you spending too much time away
							from work? Manage your time for a healthy work/life balance and you'll
							find yourself more productive in both.
						</ArticleListItem>

						<ArticleListItem title="Multitasking">
							Do you find yourself wanting to switch focus from one task to another
							too much? These context changes may be robbing you of productivity.
						</ArticleListItem>
					</ArticleList>

					<ArticleCopy>
						Do any of these warning signs apply to you? Poor time management may cause
						or contribute to these issues. Improving any of these areas are beneficial
						for a healthy and productive life. What steps can you take to improve them?
					</ArticleCopy>

					<ArticleCopy>
						While we can bring these warning signs to your attention, we may not be able
						to assist you with every one of them. But we can help improve time and task
						management for you and your team with <Brand/>. If you're ready to improve
						your productivity, get started with a free account!
						Need help with a strategy to manage your time? Check out our <RouterLink to="/time-management-101/">Time Management 101</RouterLink> resource page.
					</ArticleCopy>
				</ArticleBody>
			</ArticlePage>
		</AppLayout>
	);
}
export const query = graphql`{
  headerImg: file(relativePath: {eq: "images/articles/poor-time-management/jan-huber-RkPYFIny6bM-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH) }
  }
  sleepyCatImg: file(relativePath: {eq: "images/articles/poor-time-management/jem-sahagun-TVu6zNbZLIM-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 368, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  highwaySunsetTimeLapseImg: file(relativePath: {eq: "images/articles/poor-time-management/radek-kilijanek-JQT6xXS16dg-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 445, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
